/* PROPERTIES */
:root {
  /* Main Primary color */
  --color-primary-0: #244AA3;
  --color-primary-1: #89A0D6;
  --color-primary-2: #4A6AB2;
  --color-primary-3: #0F307C;
  --color-primary-4: #061D52;

  /* Main Complement color */
  --color-complement-0: #F3AD1F;
  --color-complement-1: #FFDD99;
  --color-complement-2: #FFC859;
  --color-complement-3: #B97C04;
  --color-complement-4: #7B5200;

  --btn-border-radius: 20px;
}

h1 {
  margin: 44px 0;
}

body {
  color: #fff;
  background-color: var(--color-primary-4);
  /* background-image: linear-gradient(to bottom, #061D52ff, #061D52cc), url(http://localhost/exdimum-survey/images/bg2.jpg); */
  /* background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh; */
  font-family: Verdana, 'Lucida Sans', Arial, sans-serif;
}

.App {
  text-align: center;
  padding: 0 20px;
}

/* THE BUTTONS */
.survey-button {
  /* background: linear-gradient(0deg, var(--color-primary-4) 8%, var(--color-primary-1) 48%, #fff 49%, #00000000 50%); */
  /* background-size: 100% 200%; */
  /* border: 2px solid var(--color-complement-1); */
  border-top: none;
  border-bottom: none;
  border-radius: 0px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font-family: Arial;
  font-size: 25px;
  font-weight: bold;
  padding: 32px 50px;
  min-width: 200px;
  height: 100%;
  width: 100%;
  border-radius: var(--btn-border-radius);
  /* animation: floodOutAnim 0.5s forwards; */
  text-shadow: 
    1px 1px 0px var(--color-primary-4), 1px -1px 0px var(--color-primary-4), 
    -1px 1px 0px var(--color-primary-4), -1px -1px 0px var(--color-primary-4);
  background-color: #0F307C2f;
  transition: background-color 0.4s ease;
}

.survey-button:hover {
  /* animation: floodInAnim 1.0s forwards; */
  background-color: #0F307Cdd;
  transition: background-color 0.4s ease;
}

@keyframes floodOutAnim {
  0% {
    background-position: 50% 87%
  }

  to {
    background-position: 50% 0%
  }
}

@keyframes floodInAnim {
  0% {
    background-position: 50% 0%
  }

  45% {
    background-position: 50% 43%
  }

  55% {
    background-position: 50% 43%
  }

  100% {
    background-position: 50% 86%
  }
}

.survey-button:active {
  /* position: relative;
  top: 2px; */
}

.survey-button .subtext {
  font-size: 16px;
  font-family: sans-serif;
  /* text-shadow: none; */
}

.image-layer {
  /* background-image: url("https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__480.jpg"); */
  display: inline-block;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: var(--btn-border-radius);
}

.image-layer:active{
  position: relative;
  top: 2px;
}

/* SURVEY HISTORY */
.survey-history-container {
  font-size: 18px;
  margin-bottom: 6px;
}

.survey-history {
  padding: 0 16px;
  margin-top: 6px;
  color: #fff;
  display: inline-block;
}

#selected {
  color: var(--color-complement-1);
}

.fade-in-anim {
  animation: fadeInOpacity ease-in 0.3s forwards;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* FLEX LAYOUT */
.flex-container {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1278px;
  min-height: 300px;
  margin: auto;
}

.flex-container-history {
  display: flex;
  gap: 5px;
}

.flex-item-1 {
  flex: 1 1 0;
  align-self: stretch;
  min-width: 280px;
}

.flex-item-2 {
  max-height: 50px;
}

.flex-item-3 {
  flex: 1 1 0;
  align-self: stretch;
  min-width: 280px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}